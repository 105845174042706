import {
    Box,
    ButtonBase,
    Divider,
    Menu,
    MenuItem,
    Typography,
    TableRow,
    TableCell,
    styled,
    tableCellClasses,
    IconButton,
    Collapse,
    Table,
    TableHead,
    TableBody
  } from '@mui/material'
  import React from 'react'
  import { CorporateCodePostData } from '../../../redux/corporateCodeService'
  import MpSvgIcon from './MpSvgIcon'
  import { KeyboardArrowDown,KeyboardArrowUp } from '@mui/icons-material'
  import { useNavigate } from 'react-router-dom'

  interface CodeListItemProps {
    code: CorporateCodePostData
  }
 
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const CodeListItem = (props: CodeListItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const isOpen = Boolean(anchorEl)
    const buttonRef = React.useRef<HTMLButtonElement>(null)
  
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (isOpen) {
        setAnchorEl(null)
      } else {
        setAnchorEl(event.currentTarget)
      }
    }
  
    const close = () => {
      setAnchorEl(null)
      buttonRef.current?.focus()
    }
    const onDelete = async () => {
     //await dispatch(removeUser(props.code as string)).unwrap()
     // dispatch(fetchUsers())
     console.log("Code Removed")
     navigate("/home/update/"+props.code.code, { replace: true,state:{
      code:props.code
     } })
    }
  
    return (
    <>
      <StyledTableRow  sx={{
        backgroundColor: isOpen ? '#ECF0FD' : 'white',
        borderRadius: '2px',
        '&:last-child td, &:last-child th': { border: 0 },
        '& > *': { borderBottom: 'unset' }
      }}
      key={props.code._id}>
          <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
         <StyledTableCell component="th" scope="row">
                {props.code.title}
              </StyledTableCell>
              <StyledTableCell align="right">{props.code.code}</StyledTableCell>
              <StyledTableCell align="right">{props.code.owner_buid}</StyledTableCell>
              <StyledTableCell align="right">{props.code.active ? 'Active' : 'Inactive'}</StyledTableCell>
          <TableCell> <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
         
          {props.code.code !== 'deleted' && (
            <ButtonBase
              sx={{ borderRadius: '10px' }}
              type="button"
              onClick={handleButtonClick}
              ref={buttonRef}
              aria-controls={isOpen ? 'simple-menu' : undefined}
              aria-expanded={isOpen || undefined}
              aria-haspopup="menu">
              <MpSvgIcon icon={isOpen ? 'threeDotsBlue' : 'threeDots'} />
            </ButtonBase>
          )}
          <Menu
            open={isOpen}
            onClose={close}
            anchorEl={anchorEl}
            sx={{ borderRadius: '20px' }}>
            <MenuItem onClick={() => onDelete()}>
              <Typography variant={'label'} color={'#ff3D00'}>
                Edit
              </Typography>
            </MenuItem>
          </Menu>
        </Box></TableCell>
        <Divider  key={props.code._id}></Divider>
      </StyledTableRow>
      <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Details
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Expiration Date</TableCell>
                  <TableCell>LIMIT</TableCell>
                  <TableCell align="right">Redeemed</TableCell>
                  <TableCell align="right">price ($) Per Employee</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                
                  <TableRow key={props.code._id}>
                    <TableCell component="th" scope="row">
                      {props.code.end_date}
                    </TableCell>
                    <TableCell>{props.code.limit}</TableCell>
                    <TableCell align="right">{props.code.allocated}</TableCell>
                    <TableCell align="right">
                      {props.code.cost_per_employee_cents / 100}
                    </TableCell>
                  </TableRow>
            
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
    </>
     
    )
  }
  
  export default CodeListItem
  