import TextField from '@mui/material/TextField'
import React, { useContext, useState } from 'react'
import PasswordField from '../shared/atom/PasswordField'
import { useLocation, useNavigate } from 'react-router-dom'
import { AnalyticsService } from '../../services/AnalyticsService'
import { AuthLoader } from '../gateways/AuthLoader'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack/Stack'

import { CognitoUser } from '@aws-amplify/auth'
import { AuthContext } from './AuthContext'
import {
  handleAuthChallenge,
  signInWithEmail,
} from '../../services/AuthenticationService'
import { handleOtpNavigation } from './LoginOTP'
import MpTheme from '../../config/MpTheme'
import { theme } from '../../config/SigninTheme'
import {  ThemeProvider, useMediaQuery } from '@mui/material'

const LoginWithEmail = () => {
  const location = useLocation()
  const from = (location?.state as any)?.from || '/'
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const onLogin = async () => {
    try {
      const user: CognitoUser = await signInWithEmail(email, password)
      const challenge = user?.challengeName
      if (challenge) {
        handleAuthChallenge({
          navigate,
          user,
          setUser: authContext.setUser,
          location,
        })
        return
      }
      navigate(from)
    } catch (error) {
      if (error instanceof Error) {
        switch (error?.name) {
          case 'UserNotFoundException':
          case 'NotAuthorizedException':
            setErrorText('The email or password entered is incorrect.')
            break
          case 'UserNotConfirmedException':
            setErrorText(
              'Your email address has not yet been verified. Check your email for a verification code.',
            )
            handleOtpNavigation(navigate, {
              actionType: 'SIGNUP_EMAIL',
              username: email,
              from,
              destination: email,
            })
            break
          case 'LimitExceededException':
            setErrorText(
              `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`,
            )
            AnalyticsService.error('CognitoLimit', error)
            break
          default:
            AnalyticsService.error(`Unhandled Authorization error: `, error)
            setErrorText(
              'An error occurred processing your login request. Please try again later.',
            )
            break
        }
      } else {
        AnalyticsService.error(`Unhandled Authorization error: `, error)
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthLoader>
        <Container fixed maxWidth={'md'}  sx={{ my: isMobile? 3:20 }} >
        <Box
        sx={{
          width:'100%',
          position: 'relative', // Set the container to relative position
          height: '100%', // Make sure the container takes the full height
        }}
      >
        <Box
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            mx: 'auto',
            my: 10,
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
            flexDirection: 'column',
          }}>
          <Typography variant={'h2'}>Sign In as an Admin</Typography>
        </Box>
          <Stack
            sx={{ maxWidth: MpTheme.layouts.widths.sm, mx: 'auto' }}
            spacing={5}>
            <TextField
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              color={'primary'}
              variant={'outlined'}
              autoComplete="email"
              InputLabelProps={{ shrink: true }}
             placeholder='Email'
            />
            <PasswordField
              fullWidth
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              variant={'outlined'}
              InputLabelProps={{ shrink: true }}
              placeholder='Password'
            />
            <Box>
              <Typography color={'error'}>{errorText}</Typography>
            </Box>
          
            <Box>
              <Button
                variant={'contained'}
                size={'large'}
                sx={{
                  mt:isMobile? 5:'unset',
                  background: '#426BF0',
                  width: '100%',
                  '&.Mui-disabled': {
                    background: '#EFEDFDB2',
                    color: '#ffffff',
                  },
                }}
                disabled={email === '' || password === ''}
                onClick={onLogin}>
                Sign In
              </Button>
            </Box>
            
          </Stack>
          
        </Box>
        </Container>
      </AuthLoader>
    </ThemeProvider>
  )
}

export default LoginWithEmail
