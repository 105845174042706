import React, { useCallback, useMemo, useReducer } from 'react'
import { Grid } from '@mui/material'
import { HomeHeader } from '../../shared/organism/AdminToolHeader'
import getColumnsForManualExecution from './getManualRemovalColumns'
import DataTable from '../../shared/molecule/DataTable'
import {
  BrokerRecordsSelector,
} from '../../../redux/brokerService'
import brokerRecordReducer, { initialState } from './brokerRecordReducer'
import Loader from '@meprism/shared/src/components/atoms/Loader'
import { BROKER_LISTS } from '@meprism/shared/src/utils/constants'
import FilterContainer from '../../shared/molecule/FilterContainer'
import { formatBrokerNames } from '../../../utils/helpers'
import useBrokerRecordsFetch from '../../../utils/hooks/useBrokerRecordsFetch'

const ManualRemovals: React.FC = () => {
  const [brokerRecordsReqParams, dispatchBrokerRecordsReqParams] = useReducer(
    brokerRecordReducer,
    {
      ...initialState,
      filters: {
        ...initialState.filters,
        brokerNames: formatBrokerNames(BROKER_LISTS),
      },
    },
  )
  const { isFetching, fetchResults } = useBrokerRecordsFetch(
    brokerRecordsReqParams,
  )
  const { records, hasNext } = BrokerRecordsSelector()
  const brokers = BROKER_LISTS

  const handlePaginationChange = useCallback(
    (key: 'page' | 'pageSize', value: number) => {
      dispatchBrokerRecordsReqParams({
        type: 'UPDATE_PAGINATION',
        payload: { key, value },
      })
    },
    [dispatchBrokerRecordsReqParams],
  )

  const handleSortChange = useCallback(
    (property: string) => {
      dispatchBrokerRecordsReqParams({
        type: 'UPDATE_SORTERS',
        payload: { property },
      })
    },
    [dispatchBrokerRecordsReqParams],
  )

  const columns = useMemo(
    () =>
      getColumnsForManualExecution(
        brokerRecordsReqParams.sort.order,
        handleSortChange,
      ),
    [brokerRecordsReqParams.sort.order, handleSortChange],
  )

  const DATA_BROKER_OPTIONS = useMemo(
    () =>
      brokers?.map((brokerName: string) => ({
        title: brokerName,
        id: brokerName,
      })) || [],
    [brokers],
  )


  return (
    <>
      <HomeHeader allBrokers />
      <FilterContainer
        handleRefetch={fetchResults}
        dataBrokerOptions={DATA_BROKER_OPTIONS}
        brokerRecordsReqParams={brokerRecordsReqParams}
        dispatchBrokerRecordsReqParams={dispatchBrokerRecordsReqParams}
        disableBroker={false}
      />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center">
        <Loader loading={isFetching}>
          <Grid xs={12} md={12}>
            <DataTable
              columns={columns}
              rows={records}
              rowType="manual-removal"
              onPageChange={(page: number) =>
                handlePaginationChange('page', page)
              }
              onPageSizeChange={(pageSize: number) =>
                handlePaginationChange('pageSize', pageSize)
              }
              hasNext={hasNext}
              pageSize={brokerRecordsReqParams.pageSize}
              page={brokerRecordsReqParams.page}
              handleSort={handleSortChange}
            />
          </Grid>
        </Loader>
      </Grid>
    </>
  )
}

export default ManualRemovals
