import {
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import React, {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import {
  BrokerRecordsSelector,
  fetchPhone,
  PhoneSelector,
  updatePhone,
} from '../../../redux/brokerService'
import { useAppDispatch } from '../../../redux/storeExports'
import { Await, useRouteLoaderData } from 'react-router-dom'
import Row from '../atom/Row'
import CustomTablePagination from '../atom/TablePagination/TablePagination'
import Loader from '@meprism/shared/src/components/atoms/Loader'
import brokerRecordReducer, {
  initialState,
} from '../../Views/ManualRemovals/brokerRecordReducer'
import FilterContainer from './FilterContainer'
import useBrokerRecordsFetch from '../../../utils/hooks/useBrokerRecordsFetch'

interface WpResultsProps {
  removed?: boolean
}

const WpResults = ({ removed }: WpResultsProps) => {
  const appDispatch = useAppDispatch()
  const { records, hasNext } = BrokerRecordsSelector()
  const phone = PhoneSelector()
  const [phoneNumber, setPhoneNumber] = useState<string | number>(phone)
  const [brokerRecordsReqParams, dispatchBrokerRecordsReqParams] = useReducer(
    brokerRecordReducer,
    {
      ...initialState,
      filters: {
        ...initialState.filters,
        brokerNames: ['whitepages'],
        brokerSources: [...initialState.filters.brokerSources, 'omit'],
      },
    },
  )
  const { isFetching, fetchResults } = useBrokerRecordsFetch(
    brokerRecordsReqParams,
  )

  const handlePaginationChange = useCallback(
    (key: 'page' | 'pageSize', value: number) => {
      dispatchBrokerRecordsReqParams({
        type: 'UPDATE_PAGINATION',
        payload: { key, value },
      })
    },
    [dispatchBrokerRecordsReqParams],
  )

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPhoneNumber(event.target.value)
  }

  const handleSave = () => {
    appDispatch(updatePhone(String(phoneNumber)))
  }

  useEffect(() => {
    const fetchPhoneNo = async () => {
      const phoneNo = await appDispatch(fetchPhone())
      setPhoneNumber(phoneNo.payload)
    }

    fetchPhoneNo()
  }, [appDispatch, phone])

  return (
    <>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <TextField
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          sx={{ width: '100%', maxWidth: '400px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ mt: 1, marginLeft: '1%' }}>
          {phoneNumber ? 'Update Number' : 'Add Number'}
        </Button>
        <Divider sx={{ mt: 4, mb: 10 }} />
      </Box>
      <FilterContainer
        handleRefetch={fetchResults}
        dataBrokerOptions={[{ title: 'Whitepages', id: 'whitepages' }]}
        brokerRecordsReqParams={brokerRecordsReqParams}
        dispatchBrokerRecordsReqParams={dispatchBrokerRecordsReqParams}
        disableBroker={true}
      />
      <Loader loading={isFetching}>
        <TableContainer
          component={Paper}
          sx={{ minWidth: 850 }}
          aria-label="simple table">
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Muid</TableCell>
                <TableCell align="right">Execution Status</TableCell>
                <TableCell align="right">ProfileUrl</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((scanresult: any, index: any) => (
                <Row
                  brokerResults={scanresult}
                  key={index}
                  rowFormat="white-pages"
                  disableRemoveButton={false}
                  manualRemovals={false}
                />
              ))}
            </TableBody>
          </Table>
          <CustomTablePagination
            page={brokerRecordsReqParams.page}
            pageSize={brokerRecordsReqParams.pageSize}
            onPageChange={(page: number) =>
              handlePaginationChange('page', page)
            }
            onPageSizeChange={(pageSize: number) =>
              handlePaginationChange('pageSize', pageSize)
            }
            hasNext={hasNext}
          />
        </TableContainer>
      </Loader>
    </>
  )
}

const WpResultSkeleton = memo(() => <Skeleton />)

const WpResultsSkeleton = memo(() => (
  <>
    {[0, 0, 0, 0, 0].map((_, index) => (
      <WpResultSkeleton key={index} />
    ))}
  </>
))

const WpResultsSuspense = (props: WpResultsProps) => {
  const loaderData = useRouteLoaderData('main') as any
  return (
    <Suspense fallback={<WpResultsSkeleton />}>
      <Await resolve={loaderData.users} errorElement={<WpResults {...props} />}>
        <WpResults {...props} />
      </Await>
    </Suspense>
  )
}

export default WpResultsSuspense
