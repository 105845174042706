import React from 'react'
import './App.css'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { Logger } from '@meprism/app-utils'
import { currentEnvironment, Environments, logLevel } from './utils/helpers'
import { RootErrorFallback } from './components/Views/ErrorViews/RootErrorFallback'
import { RootLayout, rootLayoutLoader } from './components/layouts/RootLayout'
import { SignInLayout } from './components/layouts/SignInLayout'
import SignUpEmail from './components/Authentication/SignUpWithEmail'
import LoginWithEmail from './components/Authentication/LoginWithEmail'
import PasswordReset from './components/Authentication/PasswordReset'
import ChangePassword from './components/Authentication/ChangePassword'
import Logout from './components/Authentication/Logout'
import ChangePasswordRedirect from './components/Authentication/ChangePasswordRedirectScreen'
import LoginOTP from './components/Authentication/LoginOTP'
import RequireAuth, {
  requireAuthLoader,
} from './components/Authentication/RequireAuth'
import Home from './components/Views/Home'
import WhitePages from './components/Views/WhitePages'
import { CompletePassword } from './components/Authentication/CompletePassword'
import { TotpView } from './components/Authentication/Totp'
import { MainLayout, mainLayoutLoader } from './components/layouts/MainLayout'
import EmailList from './components/shared/molecule/EmailList'
import Account, {
  accountLoader,
} from './components/Views/SettingsViews/Account'
import { SettingsLayout } from './components/layouts/SettingsLayout'
import { OnboardingCorporateProfile } from './components/Views/OnboardingCorporateProfile'
import SignUpSuccess from './components/Authentication/SignUpSuccess'
import { CorporateCodeForm } from './components/Views/Codes/CorporateCodeForm'
import UpdateCode from './components/Views/Codes/UpdateCode'
import WpResults from './components/shared/molecule/WpResults'
import ManualRemovals from './components/Views/ManualRemovals/ManualRemovals'

Logger.initializeLogger(
  {
    apiHtmlPath: '/html',
    apiLogPath: '/logs',
    apiName: 'UILogging',
    isRunningLocally: currentEnvironment() === Environments.local,
    logLevel: logLevel('DEBUG'),
    platform: 'web',
  },
  () => {},
)

//test this deployment
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      errorElement={<RootErrorFallback />}
      element={<RootLayout />}
      loader={rootLayoutLoader}>
      <Route
        element={
          <>
            {/* <AuthHeader /> */}
            <Outlet />
          </>
        }>
        <Route element={<SignInLayout />} path={'login'}>
          <Route path="signin" element={<LoginWithEmail />} />
          <Route index element={<Navigate to={'signin'} replace />} />
        </Route>
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<SignUpEmail />} />
        <Route path="/otp" element={<LoginOTP />} />
        <Route path="/success/signup" element={<SignUpSuccess />} />
        <Route path="/totp" element={<TotpView />} />
        <Route path="/signInWithEmail" element={<LoginWithEmail />} />
        <Route path="/resetPassword" element={<PasswordReset />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/completePassword" element={<CompletePassword />} />
        <Route
          path="/changePasswordSuccess"
          element={<ChangePasswordRedirect />}
        />
      </Route>
      <Route
        loader={requireAuthLoader}
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }>
        <Route path="/onboarding" element={<OnboardingCorporateProfile />} />
        <Route id={'main'} loader={mainLayoutLoader} element={<MainLayout />}>
          <Route index element={<Navigate to={'home'} replace />} />
          <Route
            path={'/home'}
            element={<Home />}
            errorElement={<RootErrorFallback />}>
            <Route
              path={'create'}
              element={<CorporateCodeForm submitLabel="Create Code" />}
            />
            <Route path={'manage'} element={<EmailList />} />
            <Route path="/home/update/:corp_code" element={<UpdateCode />} />
            <Route index element={<Navigate to={'manage'} replace />} />
          </Route>
          <Route path={'/whitepages'} element={<WhitePages />}>
            <Route path={'results'} element={<WpResults />} />
            <Route index element={<Navigate to={'results'} replace />} />
          </Route>
          <Route path={'/manual-removals'} element={<ManualRemovals />}></Route>
          <Route
            path={'/settings'}
            element={<SettingsLayout />}
            errorElement={<RootErrorFallback />}>
            <Route
              path={'account'}
              element={<Account />}
              loader={accountLoader}
            />
            <Route index element={<Navigate to={'account'} replace />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
)

function App() {
  return <RouterProvider router={router} />
}

export default App
