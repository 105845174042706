import React from 'react'
import { defer, LoaderFunction, Outlet } from 'react-router-dom'
import MpHeader from '../shared/organism/MpHeader'

export const mainLayoutLoader: LoaderFunction = async () => {
//  const users = store.dispatch(fetchUsers()).unwrap()
 // const corporateProfile = store.dispatch(getCorporateProfile()).unwrap()
 // const corporateCode = store.dispatch(fetchCode()).unwrap()
  return defer({ })
}

export const MainLayout = () => {
  return (
    <>
      <MpHeader />
      <Outlet />
    </>
  )
}
