export const BROKER_EXECUTION_STATUSES = [
  { title: 'new', id: 'new' },
  { title: 'optout_in_progress', id: 'optout_in_progress' },
  { title: 'waiting_for_verification', id: 'waiting_for_verification' },
  { title: 'completed', id: 'completed' },
]

export const BROKER_STATUSES = [
  { title: 'new', id: 'new' },
  { title: 'removed', id: 'removed' },
  { title: 'optout_in_progress', id: 'optout_in_progress' },
  { title: 'waiting_for_verification', id: 'waiting_for_verification' },
]

export const BROKER_LISTS = [
  'beenverified.com',
  'checksecrets.com',
  'clustrmaps.com',
  'infotracer.com',
  'mylife.com',
  'nuwber.com',
  'peoplefinders.com',
  'peoplewizard.net',
  'radaris.com',
  'spokeo.com',
]
