import { combineReducers } from '@reduxjs/toolkit'
import { AuthenticationReducer } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { CorporateProfileReducer } from './CorporateProfile/CorporateProfileSlice'
import { CorporateReducer } from './corporateService'
import { CorporateCodeServiceReducer } from './corporateCodeService'
import { BrokerServiceReducer } from './brokerService'

const appReducer = combineReducers({
  authentication: AuthenticationReducer,
  corporateProfile: CorporateProfileReducer,
  corporateService: CorporateReducer,
  corporateCodeService: CorporateCodeServiceReducer,
  brokerService: BrokerServiceReducer,
})

export const RootReducer: typeof appReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
