import Container from '@mui/material/Container'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { SettingsHeader } from '../shared/organism/SettingsHeader'

export const SettingsLayout = () => {
  return (
    <>
      <SettingsHeader />
      <Container maxWidth={'xl'} sx={{ px: 5, mx: 'auto' }}>
        <Outlet />
      </Container>
    </>
  )
}
