import React from "react";
import { useLocation } from 'react-router-dom';
import { CorporateCodePost } from "../../../redux/corporateCodeService";
import { CorporateCodeForm } from "./CorporateCodeForm";
interface PropState {
    code: CorporateCodePost
} 
const UpdateCode = () =>{
    const location = useLocation();
    let { code } = location.state as PropState;
    code = {...code,cost_per_employee_cents:code.cost_per_employee_cents/100}
    return(
        <CorporateCodeForm submitLabel='Update Code' corporateCodeToEdit={code} />
    )
}

export default UpdateCode;