import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { Toast } from '../components/toast/toast'
import { RootState } from './store'
import { useSelector } from 'react-redux'
import {
  IBrokerParams,
  IBrokerRecordsParams,
  IBrokerResultsState,
} from '../components/shared/atom/inputs/brokerRecordTypes'

export const initialBrokerResultsState: IBrokerResultsState = {
  brokerResults: { records: [], hasNext: false },
  phone: '',
  brokers: [],
}

const getBrokerRecordsApi = async (
  params: IBrokerRecordsParams,
): Promise<any> => {
  return API.post('CorpOnboarding', '/records', {
    body: {
      page: params.page,
      page_size: params.pageSize,
      sort: params.sort,
      filters: {
        broker_names: params.filters.brokerNames,
        muid: params.filters.muid,
        statuses: params.filters.statuses,
        execution_statuses: params.filters.executionStatuses,
        broker_sources: params.filters.brokerSources,
        start_date: params.filters.startDate,
        end_date: params.filters.endDate,
      },
    },
  })
}

const exportBrokerRecordsApi = async (
  params: IBrokerRecordsParams,
): Promise<any> => {
  return API.post('CorpOnboarding', '/export_records', {
    body: {
      filters: {
        broker_names: params.filters.brokerNames,
        muid: params.filters.muid,
        statuses: params.filters.statuses,
        execution_statuses: params.filters.executionStatuses,
        broker_sources: params.filters.brokerSources,
        start_date: params.filters.startDate,
        end_date: params.filters.endDate,
      },
    },
  })
}

const getPhoneApi = async (): Promise<any> => {
  return API.get('CorpOnboarding', '/wpphone', {})
}

const updatePhoneApi = async (number: string): Promise<any> => {
  console.log('updating the phone to ' + number)
  return API.post('CorpOnboarding', '/wpphone', { body: { number } })
}

const updateStatusToComplete = async (
  recordId: string,
  updatingStatus: string,
) => {
  return API.put('CorpOnboarding', '/removal_table/complete_statuses', {
    body: { record_id: recordId, updating_status: updatingStatus },
  })
}

const getBrokersApi = async (params: IBrokerParams): Promise<any> => {
  return API.post('CorpOnboarding', '/brokers', {
    body: {
      filters: {
        scope: params.filters.scope,
        unique_broker_name: params.filters.unique_broker_name,
      },
    },
  })
}

export const fetchBrokers = createAsyncThunk(
  'fetchBrokers',
  async (params: IBrokerParams) => {
    try {
      return await getBrokersApi(params)
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Error fetching brokers data',
      })
      throw error
    }
  },
)

export const fetchBrokerRecords = createAsyncThunk(
  'fetchBrokerRecords',
  async (params: IBrokerRecordsParams) => {
    try {
      const response = await getBrokerRecordsApi(params)
      return { result: response.records, hasNext: response.has_next }
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `Error fetching ${
          params.filters.brokerNames.includes('whitepages')
            ? 'whitepages'
            : 'Manual removals'
        } results`,
      })
      throw error
    }
  },
)

export const exportBrokerRecords = createAsyncThunk(
  'exportBrokerRecords',
  async (params: IBrokerRecordsParams) => {
    try {
      exportBrokerRecordsApi(params)
      Toast.show({
        type: 'success',
        text1: `You will get an email shortly with the results`,
      })
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `Error exporting records`,
      })
      throw error
    }
  },
)

export const fetchPhone = createAsyncThunk('fetchPhone', async () => {
  try {
    return await getPhoneApi()
  } catch (error) {
    Toast.show({
      type: 'error',
      text1: 'Error fetching phone data',
    })
    throw error
  }
})

export const updatePhone = createAsyncThunk(
  'updatePhone',
  async (number: string) => {
    try {
      const response = await updatePhoneApi(number)
      Toast.show({
        type: 'success',
        text1: 'Number updated',
      })
      return response
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Error updating phone data',
      })
      throw error
    }
  },
)

export const updateRemovalTableStatus = async (
  recordId: string,
  updatingStatus: string,
) => {
  try {
    const response = await updateStatusToComplete(recordId, updatingStatus)
    Toast.show({
      type: 'success',
      text1: `${updatingStatus.toUpperCase()} is updated`,
    })
    return response
  } catch (error) {
    Toast.show({
      type: 'error',
      text1: `Error updating ${updatingStatus.toUpperCase()}`,
    })
  }
}

const brokerServiceSlice = createSlice({
  name: 'BrokerService',
  initialState: initialBrokerResultsState,
  reducers: {
    populateBrokerResults: (
      state,
      { payload }: PayloadAction<Partial<IBrokerResultsState>>,
    ) => {
      state.brokerResults = {
        records: payload.brokerResults?.records || [],
        hasNext: payload.brokerResults?.hasNext,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrokerRecords.fulfilled, (state, { payload }) => {
      state.brokerResults = {
        records: payload.result || [],
        hasNext: payload.hasNext,
      }
    })
    builder.addCase(fetchPhone.fulfilled, (state, { payload }) => {
      state.phone = payload
    })
    builder.addCase(fetchBrokers.fulfilled, (state, { payload }) => {
      state.brokers = payload
    })
  },
})

export const BrokerServiceActions = brokerServiceSlice.actions
export const BrokerServiceReducer = brokerServiceSlice.reducer

export const BrokerServiceSelector = (state: RootState) => state.brokerService

export const BrokerRecordsSelector = () => {
  return useSelector((state: RootState) => state.brokerService.brokerResults)
}

export const PhoneSelector = () => {
  return useSelector((state: RootState) => state.brokerService.phone)
}

export const BrokersSelector = () => {
  return useSelector((state: RootState) => state.brokerService.brokers)
}
