import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from '../../redux/storeExports'
import { fetchBrokerRecords } from '../../redux/brokerService'
import { IBrokerRecordsParams } from '../../components/shared/atom/inputs/brokerRecordTypes'
const useBrokerRecordsFetch = (
  brokerRecordsReqParams: IBrokerRecordsParams,
) => {
  const [isFetching, setIsFetching] = useState(false)
  const appDispatch = useAppDispatch()
  const fetchResults = useCallback(async () => {
    setIsFetching(true)
    await appDispatch(fetchBrokerRecords(brokerRecordsReqParams))
    setIsFetching(false)
  }, [appDispatch, brokerRecordsReqParams])
  useEffect(() => {
    fetchResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    brokerRecordsReqParams.pageSize,
    brokerRecordsReqParams.page,
    brokerRecordsReqParams.sort,
  ])
  return { isFetching, fetchResults }
}
export default useBrokerRecordsFetch