import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import background from '../../../assets/images/CreateBusinessAccount.png'
import Typography from '@mui/material/Typography'
import logo from '@meprism/shared/src/assets/icons/logos/mePrism_business.svg'
import { useMediaQuery } from '@mui/material'
import { theme } from '../../../config/SigninTheme'

function SignInSideLeft() {
  const location = useLocation()
  const hideTabs = location.pathname.includes('onboarding')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        p: 10,
      }}>
        <Box
        sx={{
          position: 'relative', // Set the container to relative position
          height: '100%', // Make sure the container takes the full height
        }}
      >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'left',
        }}>
        {!isMobile && (
          <img src={logo} alt="Logo" style={{ width: 200, height: 42,marginTop:10 }} />
        )}
        {!hideTabs ? (
          <Typography variant="h1" component="div" sx={{ marginTop: 20 }}>
            Admin Portal to create Corp Codes
          </Typography>
        ) : (
          <Typography variant="h1" component="div" sx={{ marginTop: 20 }}>
            Are you Admin?
          </Typography>
        )}
      </Box>
  
     </Box>
    </Grid>
  )
}

export default SignInSideLeft
