import { Logger } from '@meprism/app-utils'
import {
  AuthenticationState,
  MeprismEntitlement,
  MfaType,
  AmplifyAuthEvent,
} from './authenticationTypes'
import { AuthenticationService } from '../../services/AuthenticationService'
import { Auth, Cache } from 'aws-amplify'
import { AuthError, LoginState } from './authenticationTypes'
import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  PayloadAction,
} from '@reduxjs/toolkit'
import { createBaseAsyncThunk } from '../base'
import { CognitoUser } from 'amazon-cognito-identity-js'
import {createCachedSelector} from 're-reselect';

const initialState: AuthenticationState = {
  muid: null,
  guestUser: false,
  authError: undefined,
  accountEmail: undefined,
  entitlements: [],
  meta: { blockingRequestIds: [], completedThunks: {} },
  loginState: undefined,
  preferredMfa: {
    registeredPreferredMfa: 'NOMFA',
    draftPreferredMfa: 'NOMFA',
  },
}

interface BaseState {
  authentication: AuthenticationState
}

export const handleEntitlementChange = async () => {
  Logger.info('Got entitlements change event, refreshing auth token')
  // in case of entitlement change, we want to get a new auth token
  return await Auth.currentAuthenticatedUser({ bypassCache: true })
}

export const USER_PASSWORD_SERVICE_KEY = 'userpassword'

export const fetchInitialAuthStatus = createBaseAsyncThunk(
  'authentication/fetchInitialAuthStatus',
  async (_, { dispatch }) => {
    try {
      //do the stuff that was done on earlier app initialization
      const currentUser = await AuthenticationService.currentUser()
      if (currentUser) {
        await dispatch(postConfirmLogin(currentUser)).unwrap()
        return true
      } else {
        dispatch(AuthenticationActions.setMuid(null))
      }
    } catch (error) {
      if (
        error?.name === 'NotAuthorizedException' ||
        error?.name === 'UserNotFoundException' ||
        error?.includes?.('No current user') ||
        error?.includes?.('User does not exist')
      ) {
        Logger.debug(
          'NotAuthorized during app startup - likely user is not logged in.',
        )
        return
      }
      Logger.error(`App: Error finding initial user auth status ${error}`)
    }
    return false
  },
)

export const signInWithGoogle = createAsyncThunk(
  'authentication/signInWithGoogle',
  async (destination: string | undefined) => {
    await AuthenticationService.signInWithGoogle(destination)
  },
)

export const signInWithApple = createAsyncThunk(
  'signInWithGoogle',
  async (destination: string | undefined) => {
    await AuthenticationService.signInWithApple(destination)
  },
)

export const identifyUserWithMixpanel = createBaseAsyncThunk(
  'authentication/identifyUserWithMixpanel',
  async (payload: { muid: string }, { extra }) => {
    try {
      const { muid } = payload
      const muidInMixpanel = await extra.AnalyticsManager.getSuperProperty(
        'muid',
      )
      Logger.info(`Got muid as ${muid}, muid in Mixpanel: ${muidInMixpanel}`)
      if (muid && muid !== muidInMixpanel) {
        Logger.info(`Registering ${muid} with mixpanel`)
        await extra.AnalyticsManager.registerSuperProperties({ muid })
        await extra.AnalyticsManager.identify(muid)
      }
    } catch (error) {
      Logger.error(`Error registering user identity with Mixpanel: ${error}`)
      throw error
    }
  },
)

const getEntitlementsFromPayload = (payload: Record<string, unknown>) => {
  if (!payload) {
    return []
  }
  return Object.keys(payload)
    .filter((key) => key && key?.startsWith?.('meprism:'))
    .sort()
}

export const refreshEntitlementsFromSession = createAsyncThunk(
  'authentication/refreshEntitlementsFromSession',
  async (_, { dispatch }) => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser()
      const payload = user?.getSignInUserSession?.()?.getIdToken?.()?.payload
      if (!payload) {
        return
      }
      const entitlements = getEntitlementsFromPayload(payload)
      dispatch(AuthenticationActions.setEntitlements(entitlements))
    } catch (error) {
      // this really shouldn't happen because we probably should be logged in for this
      Logger.error(`Error refreshing entitlements: ${error}`)
    }
  },
)

export const postConfirmLogin = createBaseAsyncThunk(
  'authentication/postConfirmLogin',
  async (user: CognitoUser, { dispatch, extra }) => {
    try {
      const payload = user?.getSignInUserSession?.()?.getIdToken?.()?.payload

      // the other user pool uses a different key for the muid - it's basically a user ID, and
      // should be treated the same in code
      const muid: string =
        payload?.['custom:muid'] ??
        payload?.['custom:buid'] ??
        payload?.['custom:admin_muid']
      const email: string = payload?.email
      if (!payload || !muid) {
        throw new Error(`Null muid in postConfirmLogin, ${payload} ${user}`)
      }
      const entitlements = getEntitlementsFromPayload(payload)
      // the success of guest user log in doesn't clear an auth error, because a guest
      // user could have been trying to sign into a named account so we show that error
      // I hate this too, but such is life
      // so guest users keep whatever the previous auth error was
      dispatch(
        AuthenticationActions.updateAuth({
          muid,
          guestUser: false,
          accountEmail: email,
          entitlements,
          authError: undefined,
          // Explicitly unset login state info on successful login
          loginState: undefined,
        }),
      )
      await dispatch(identifyUserWithMixpanel({ muid }))

      // if we're not a Guest user, we don't need a transfer token and we also don't need
      // guest credentials
      extra.Keychain.resetGenericPassword({
        service: USER_PASSWORD_SERVICE_KEY,
      })

      // if we're not a guest user, tell iaphub
      dispatch(AuthenticationActions.removeBlockingRequest('SIGNIN'))
    } catch (e) {
      Logger.error(
        `App: Error finding user and setting user state ${e} ${e?.message}`,
      )
      throw e
    }
  },
)

export const deleteUserAccount = createBaseAsyncThunk(
  'authentication/deleteUserAccount',
  async (_, { dispatch, extra, getState }) => {
    const email = (getState() as BaseState).authentication?.accountEmail
    try {
      await extra.API.del('User', '/current', {})
    } catch (error) {
      Logger.error(`Error deleting user: ${error}`)
      extra.Toast.show({
        type: 'error',
        text1: 'There was an error',
        text2:
          'Your account could not be fully deleted automatically. A log of this issue has been created. If you have more questions, contact support.',
        onPress: extra.handleSupport,
      })
      // this one is mission critical
      throw error
    }
    // but we should probably keep going in this case? Judgement call though.
    try {
      await dispatch(logOutUser()).unwrap()
    } catch (error) {
      // This REALLY should not be throwing EVER
      // in any case we can't really even log at this point?
      extra.AnalyticsManager.error(error)
    }
    if (
      extra.Configuration?.WEBAPP_DOMAIN &&
      extra.Configuration?.PLATFORM === 'ios' &&
      email
    ) {
      try {
        // in case of account deletion, we can null out the pw
        // see https://developer.apple.com/documentation/security/shared_web_credentials/managing_shared_credentials
        await extra.Keychain.setSharedWebCredentials(
          extra.Configuration.WEBAPP_DOMAIN,
          email,
          undefined,
        )
      } catch (error) {
        // in case we can't set credentials, not much to do
      }
    }
  },
)

export const logOutUser = createBaseAsyncThunk(
  'authentication/logOutUser',
  async (_, { dispatch, getState, extra }) => {
    const oldState = getState() as BaseState
    const oldAuthState = oldState.authentication
    extra.AnalyticsManager.trackTypedEvent({
      event: 'Sign Out',
    })
    try {
      await Auth.signOut({ global: true })
    } catch (error) {
      try {
        Logger.error(`User Logout Error: ${error}`)
      } catch (anotherError) {
        // of course we can't log - we could be logged out of course
        extra.AnalyticsManager.error('User Logout Error', { error })
      }
      // Do not throw here - let the log out go through
    }

    // This is some legacy stuff where we are manually dumping actions.
    // @TODO: Fix this
    dispatch({ type: 'USER_LOGOUT' })
    // clear amplify cache - I don't think this actually DOES anything auth wise but !shrug
    Cache.clear()
    if (!oldAuthState?.guestUser) {
      // only reset analytics on named user
      // if they're a guest, they're the same person
      extra.AnalyticsManager.reset()
      // on iOS, this deletes the persistent anon id
      // don't do this to a guest user, could cause them to lose purchases
      extra.Keychain.resetGenericPassword({ service: 'iaphub' })
    }
  },
)

// this is only a Thunk so we can set a blocking loader
export const signInWithEmail = createBaseAsyncThunk(
  'authentication/signInWithEmail',
  async ({ username, password }: { username: string; password: string }) => {
    return await Auth.signIn(username, password)
  },
)

// this is only a Thunk so we can set a blocking loader
export const submitForgotPassword = createBaseAsyncThunk(
  'authentication/submitForgotPassword',
  async (
    {
      username,
      code,
      password,
    }: {
      username: string
      code: string
      password: string
    },
    { extra },
  ) => {
    await Auth.forgotPasswordSubmit(username, code, password)
    if (
      extra.Configuration?.WEBAPP_DOMAIN &&
      extra.Configuration?.PLATFORM === 'ios'
    ) {
      try {
        extra.Keychain.setGenericPassword(username, password, {
          service: USER_PASSWORD_SERVICE_KEY,
        })
        await extra.Keychain.setSharedWebCredentials(
          extra.Configuration.WEBAPP_DOMAIN,
          username,
          password,
        )
      } catch (error) {
        // not much to do if this fails, could be because user denies permission etc
      }
    }
  },
)

// this is only a Thunk so we can set a blocking loader
export const signUpWithEmailThunk = createBaseAsyncThunk(
  'authentication/signUpWithEmailThunk',
  async (
    { email, password }: { email: string; password: string },
    { dispatch, rejectWithValue, extra },
  ) => {
    try {
      await AuthenticationService.signUpWithEmail(email, password)
      dispatch(
        AuthenticationActions.setLoginState({
          state: 'CONFIRMING_EMAIL',
          email,
        }),
      )
      extra.Keychain.setGenericPassword(email, password, {
        service: USER_PASSWORD_SERVICE_KEY,
      })
    } catch (error) {
      // this is a RARE case where we actually want to reject with value here -
      // previous users of signUp were expecting any errors to come back as STRINGS
      // not as errors, so we can keep that contract for them
      if (typeof error !== 'string') {
        return rejectWithValue('Unknown')
      }
      return rejectWithValue(error)
    }
  },
)

export const handleAutoSigninFailure = createBaseAsyncThunk(
  'authentication/handleAutoSigninFailure',
  async (_, { dispatch, extra }) => {
    try {
      const creds = await extra.Keychain.getGenericPassword({
        service: USER_PASSWORD_SERVICE_KEY,
      })
      if (!creds) {
        throw new Error('No user credentials available')
      }
      const { username, password } = creds
      await dispatch(
        signInWithEmail({
          username,
          password,
        }),
      ).unwrap()
    } catch (error) {
      Logger.error(`Error handling autosignin failure: ${error}`)
      throw error
    }
  },
)

export const getPreferredMfa = createBaseAsyncThunk(
  'authentication/getPreferredMfa',
  async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      return (await Auth.getPreferredMFA(user)) as MfaType
    } catch (error) {
      return undefined
    }
  },
)

const { actions, reducer } = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setMuid: (state, { payload }: { payload: string | null }) => {
      state.muid = payload
    },
    setAuthError: (state, { payload }: { payload: AuthError }) => {
      state.authError = payload
    },
    clearAuthError: (state) => {
      state.authError = undefined
    },
    setEntitlements: (state, { payload }: { payload: string[] }) => {
      state.entitlements = payload
    },
    updateAuth: (
      state,
      { payload }: { payload: Partial<AuthenticationState> },
    ) => ({ ...state, ...payload }),
    setLoginState: (
      state,
      { payload }: PayloadAction<LoginState | undefined>,
    ) => {
      state.loginState = payload
    },
    addBlockingRequest: (state, { payload }: { payload: string }) => {
      state.meta.blockingRequestIds.push(payload)
    },
    removeBlockingRequest: (state, { payload }: { payload: string }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== payload,
      )
    },
    setDraftMfa: (state, { payload }: PayloadAction<MfaType>) => {
      state.preferredMfa.draftPreferredMfa = payload
    },
    emitAmplifyEvent: (_state, _action: PayloadAction<AmplifyAuthEvent>) => {
      // this intentionally does nothing, but is a typesafe placeholder for easy listening
    },
  },
  extraReducers: (builder) => {
    const blockingRequests = [
      fetchInitialAuthStatus,
      deleteUserAccount,
      logOutUser,
      signInWithEmail,
      submitForgotPassword,
      signUpWithEmailThunk,
    ] as const
    blockingRequests.forEach((thunk) => {
      builder.addCase(thunk.pending, (state, { meta }) => {
        state.meta.blockingRequestIds.push(meta.requestId)
      })
      builder.addCase(thunk.fulfilled, (state, { meta }) => {
        state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
          (id) => id !== meta.requestId,
        )
      })
      builder.addCase(thunk.rejected, (state, { meta }) => {
        state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
          (id) => id !== meta.requestId,
        )
      })
    })
    builder.addCase(getPreferredMfa.fulfilled, (state, action) => {
      state.preferredMfa.registeredPreferredMfa = action.payload || 'NOMFA'
      state.preferredMfa.draftPreferredMfa = action.payload || 'NOMFA'
    })
    builder.addMatcher(isFulfilled, (state, action) => {
      if (action.type.startsWith('authentication')) {
        state.meta.completedThunks[action.type.replace('/fulfilled', '')] = true
      }
    })
  },
})

export const selectIsAuthLoading = (state: BaseState) =>
  state?.authentication?.meta?.blockingRequestIds?.length > 0

export const selectEntitlements = (state: BaseState) =>
  state.authentication?.entitlements || []

export const selectAccountEmail = (state: BaseState) =>
  state.authentication?.accountEmail

export const selectMuid = (state: BaseState) => state.authentication?.muid

// this type of operation frequently ought to use caching
// in this case the array will always be very short.
export const selectEntitlementByName = createCachedSelector(
  selectEntitlements,
  (state: BaseState, entitlement: MeprismEntitlement) => entitlement,
  (entitlements, entitlement) => entitlements.includes(entitlement),
)((state, entitlement) => entitlement)

export const selectCompletedAuthenticationThunks = (state: BaseState) =>
  state.authentication.meta.completedThunks

export const selectPreferredMfa = (state: BaseState) =>
  state.authentication.preferredMfa

export const AuthenticationActions = actions
export const AuthenticationReducer = reducer

export type AuthenticationReducerType = ReturnType<typeof AuthenticationReducer>
