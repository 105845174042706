import { useCallback, useState } from 'react'
import { Grid, IconButton, MenuItem, Select, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

type ICustomTablePaginationProps = {
  onPageSizeChange: (event: any) => void
  onPageChange: (event: any) => void
  hasNext?: boolean
  pageSize: number // this is to set the value of the select
  page: number
}

const DEFAULT_PAGINATION_OPTIONS = [10, 25, 100]

const CustomTablePagination = ({
  onPageSizeChange,
  onPageChange,
  hasNext,
  page,
  pageSize,
}: ICustomTablePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  const handlePageChange = useCallback(
    (newPage: number) => {
      onPageChange(newPage)
      setCurrentPage(newPage)
    },
    [onPageChange],
  )

  const handleChangePageSize = useCallback(
    (event: any) => {
      onPageSizeChange(Number(event.target.value))
      setCurrentPageSize(Number(event.target.value))
      setCurrentPage(1)
    },
    [onPageSizeChange],
  )

  return (
    <Grid
      container
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: 'background.paper',
        boxShadow: 1,
        borderRadius: 1,
      }}>
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ marginRight: '8px' }}>
          Rows per page:
        </Typography>
        <Select
          value={currentPageSize}
          onChange={handleChangePageSize}
          sx={{ minWidth: '80px' }}>
          {DEFAULT_PAGINATION_OPTIONS.map((current) => (
            <MenuItem key={current} value={current}>
              {current}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item lg={2} sx={{ textAlign: 'center' }}>
        <Typography variant="body2">Page: {currentPage}</Typography>
      </Grid>
      <Grid item lg={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="left-arrow"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ marginRight: '8px' }}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          aria-label="right-arrow"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!hasNext}>
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default CustomTablePagination
