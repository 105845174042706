import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Toast } from '../../toast/toast'
import MpTheme from '../../../config/MpTheme'
import dayjs, { Dayjs } from 'dayjs'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {
  CorporateCodePost,
  createCorporateCode,
  updateCorporateCode,
} from '../../../redux/corporateCodeService'
import { useAppDispatch } from '../../../redux/storeExports'
import { fonts } from '../../../config/SigninTheme'
import { useNavigate, useParams } from 'react-router-dom'

export type CorporateFormProps = {
  submitLabel: string
  onSubmit?: () => void
  corporateCodeToEdit?: CorporateCodePost
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const entitlements_list = [
  'meprism:databroker200',
  'meprism:breach',
  'meprism:ppc',
  'meprism:4screen',
]

export const CorporateCodeForm = ({
  submitLabel,
  onSubmit,
  corporateCodeToEdit,
}: CorporateFormProps) => {
  const { handleSubmit } = useForm()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const today = new Date()
  const [entitlmentName, setEntitlmentName] = React.useState<string[]>([])

  const [formData, setFormData] = useState<CorporateCodePost>({
    active: true,
    code: '',
    description: '',
    duration: 365,
    duration_type: 'absolute',
    end_date: '',
    entitlements: [],
    expiration_date: today.toISOString() ?? '',
    limit: 0,
    owner_buid: '',
    title: '',
    cost_per_employee_cents: 150,
    type: 'default',
    federation_type: null,
  })

  useEffect(() => {
    if (corporateCodeToEdit) {
      setFormData({
        ...corporateCodeToEdit,
        type: corporateCodeToEdit?.authentication?.type || 'default',
        federation_type: corporateCodeToEdit?.authentication?.federation_opts?.type || null
      })
      setEntitlmentName(corporateCodeToEdit.entitlements)
    }
  }, [corporateCodeToEdit])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
    console.log(e.target.name)
  }

  const handleDateChange = (date: Dayjs | null) => {
    setFormData({
      ...formData,
      expiration_date: date ? date.toISOString() : '',
      end_date: date ? date.toISOString() : '',
    })
    console.log(formData.expiration_date)
  }

  const handleSwitch = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      active: !formData.active,
    })
  }

  const handleDurationChange = (event: SelectChangeEvent) => {
    setFormData({
      ...formData,
      duration_type: event.target.value as string,
    })
  }

  const handleAuthenticationChange = (event: SelectChangeEvent) => {
    const federated = event.target.value === 'federation'
    setFormData({
      ...formData,
      type: federated ? 'federation' : 'default',
      federation_type: federated ? 'okta' : null,
    })
  }

  const handleSelectChange = (
    event: SelectChangeEvent<typeof entitlmentName>,
  ) => {
    const {
      target: { value },
    } = event
    setEntitlmentName(typeof value === 'string' ? value.split(',') : value)
    let selectedEntitlement = value as string[]
    setFormData({
      ...formData,
      entitlements: selectedEntitlement,
    })
    console.log(value)
  }

  const transformFormData = (data: any) => {
    const newObject = {
      ...data,
      authentication: {
        type: data.type,
        federation_type: data.type === 'federation' ? data.federation_type : null,
      },
    }
    delete newObject['type']
    delete newObject['federation_type']

    return newObject
  }

  async function onSubmitData() {
    if (!validateFormData()) return

    try {
      console.log(formData)
      if (corporateCodeToEdit) {
        const corp_code = params?.corp_code ?? ''
        const res = await dispatch(
          updateCorporateCode({
            corp_code,
            formData: transformFormData(formData),
          }),
        )
        if (res.payload) navigate('/home/manage', { replace: true })
      } else {
        const res = await dispatch(
          createCorporateCode(transformFormData(formData)),
        )
        console.log(res)
        if (res.payload) navigate('/home/manage', { replace: true })
      }
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }

  function validateFormData() {
    if (isNaN(formData.limit) || formData.limit < 1) {
      Toast.show({
        type: 'error',
        text1: 'Limit should be number and greater than 1',
      })
      return false
    }
    const currentDateTime = new Date()
    const expirationDate = new Date(formData.expiration_date)

    if (expirationDate <= currentDateTime) {
      Toast.show({
        type: 'error',
        text1: 'Expiration date should be a future date',
      })
      return false
    }
    return true
  }

  return (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: MpTheme.layouts.widths.md,
          width: '100%',
          mx: 'auto',
        }}>
        <Stack
          spacing={5}
          sx={{
            maxWidth: MpTheme.layouts.widths.md,
            width: '100%',
            mx: 'auto',
          }}>
          <Box>
            <Typography variant={'h2'} sx={{ whiteSpace: 'break-spaces' }}>
              Code
            </Typography>
            <TextField
              name="code"
              placeholder="Code"
              required
              fullWidth
              disabled={!!corporateCodeToEdit}
              value={formData.code}
              InputProps={{ inputMode: 'numeric' }}
              onChange={handleChange}
              sx={{
                height: '38px',
                '& .MuiInput-root': {
                  height: '42px',
                },
                '& .MuiInputLabel-root': {
                  marginBottom: '5px',
                },
              }}
            />
          </Box>
          <Box>
            <Typography variant={'h2'} sx={{ whiteSpace: 'break-spaces' }}>
              Title
            </Typography>
            <TextField
              name="title"
              placeholder="Title"
              required
              fullWidth
              value={formData.title}
              InputProps={{ inputMode: 'numeric' }}
              onChange={handleChange}
              sx={{
                height: '38px',
                '& .MuiInput-root': {
                  height: '42px',
                },
                '& .MuiInputLabel-root': {
                  marginBottom: '5px',
                },
              }}
            />
          </Box>
          <Box>
            <Typography variant={'h2'} sx={{ whiteSpace: 'break-spaces' }}>
              Description
            </Typography>
            <TextField
              name="description"
              placeholder="Description"
              required
              fullWidth
              value={formData.description}
              onChange={handleChange}
              sx={{
                height: '38px',
                '& .MuiInput-root': {
                  height: '40px',
                },
              }}
            />
          </Box>
          <Box>
            <Typography variant={'h2'} sx={{ whiteSpace: 'break-spaces' }}>
              Max Redemption
            </Typography>
            <TextField
              name="limit"
              placeholder="Max Redemption"
              required
              fullWidth
              onChange={handleChange}
              value={formData.limit}
              sx={{
                height: '38px',
                '& .MuiInput-root': {
                  height: '40px',
                },
              }}
            />
          </Box>
          <Box>
            <Typography variant={'h2'} sx={{ whiteSpace: 'break-spaces' }}>
              Cost Per Employee
            </Typography>
            <TextField
              name="cost_per_employee_cents"
              placeholder="Max Redemption"
              required
              fullWidth
              onChange={handleChange}
              value={formData.cost_per_employee_cents}
              sx={{
                height: '38px',
                '& .MuiInput-root': {
                  height: '40px',
                },
              }}
            />
          </Box>
          <Box>
            <Typography variant={'h2'} sx={{ whiteSpace: 'break-spaces' }}>
              Owner BUID
            </Typography>
            <TextField
              name="owner_buid"
              placeholder="Owner BUID"
              disabled={!!corporateCodeToEdit}
              required
              fullWidth
              onChange={handleChange}
              value={formData.owner_buid}
              sx={{
                height: '38px',
                '& .MuiInput-root': {
                  height: '40px',
                },
              }}
            />
          </Box>
          <Box>
            <FormControl sx={{ m: 1, width: '100%' }}>
              <InputLabel id="demo-multiple-name-label">Entitlement</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={entitlmentName}
                onChange={handleSelectChange}
                required
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}>
                {entitlements_list.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Duration Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.duration_type}
                label="Duration Type"
                onChange={handleDurationChange}>
                <MenuItem value="absolute">Absolute</MenuItem>
                <MenuItem value="relative">Relative</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {formData.duration_type === 'relative' && (
            <Box>
              <Typography variant={'h2'} sx={{ whiteSpace: 'break-spaces' }}>
                Duration
              </Typography>
              <TextField
                name="duration"
                placeholder="Days"
                required
                fullWidth
                onChange={handleChange}
                value={formData.duration}
                sx={{
                  height: '38px',
                  '& .MuiInput-root': {
                    height: '40px',
                  },
                }}
              />
            </Box>
          )}
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  label="Expiration Date"
                  value={dayjs(formData.expiration_date)}
                  onChange={handleDateChange}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography variant={'subheader'}>Status</Typography>
            <Switch checked={formData.active} onChange={handleSwitch} />
          </Box>
          <Box sx={{mt: '10px' }}>
            <FormControl fullWidth>
              <InputLabel>Authentication Type</InputLabel>
              <Select
                value={formData.type}
                label="Authentication Type"
                onChange={handleAuthenticationChange}>
                <MenuItem value="default">default</MenuItem>
                <MenuItem value="federation">federation</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ mt: '15px' }}>
              {formData?.type === 'federation' && (
                <>
                  <InputLabel sx={{ mt: '15px' }}>Federation Type</InputLabel>
                  <Select value={formData.federation_type} label="Faderated Type" disabled>
                    <MenuItem value="okta">Okta</MenuItem>
                  </Select>
                  <InputLabel sx={{ mt: '15px' }}>Federation Name</InputLabel>
                  <TextField
                    value={`Okta-${formData.code}`.toLowerCase()}
                    disabled
                  />
                </>
              )}
            </Box>
          </Box>
        </Stack>
        <Box sx={{ mt: 5 }}>
          <Button
            variant={'contained'}
            type={'submit'}
            color={'darkPurple'}
            sx={{
              background: '#426BF0',
              width: '100%',
              '&.Mui-disabled': {
                background: '#426BF0',
                fontFamily: fonts.Inter.SemiBold,
                color: '#ffffff',
              },
            }}>
            {submitLabel}
          </Button>
        </Box>
      </Stack>
    </form>
  )
}