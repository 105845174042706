import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'
import React from 'react'

interface CustomDatePickerProps {
  label: string
  value: Dayjs | null
  disableFuture?: boolean
  minDate?: Dayjs
  onChange: (value: null | Dayjs) => void
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = React.memo(
  ({ label, value, onChange, disableFuture = false, minDate }) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: '100%', fontSize: '12px' }}
        label={label}
        value={value}
        onChange={onChange}
        disableFuture={disableFuture}
        minDate={minDate}
      />
    </LocalizationProvider>
  ),
)

export default CustomDatePicker
