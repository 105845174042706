import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import MpTheme from '../../config/MpTheme'
import logo from '@meprism/shared/src/assets/icons/logos/mePrism_business.svg'
import { CorporateProfileForm } from './Profile/CorporateProfileForm'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import SignInSideLeft from '../shared/organism/SignInLeftLayout'
import * as SigninTheme from '../../config/SigninTheme'
import * as themenew from '../../theme/theme'
import { useLocation } from 'react-router-dom';
import { MpReColorPalette } from '../../config/SigninTheme'

export const OnboardingCorporateProfile = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const isMobile = useMediaQuery(SigninTheme.theme.breakpoints.down('sm'))
  const choosetheme = location.pathname.includes('onboarding')
  const onboardingTheme = SigninTheme.theme
  const defaultTheme = themenew.theme
  return (
    <>
      <ThemeProvider theme={choosetheme ? onboardingTheme : defaultTheme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          {!isMobile && <SignInSideLeft />}
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            square
            sx={{
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'left',
              p: 5,
              backgroundColor: MpReColorPalette.background.default,
            }}>
              <Box sx={{position:'relative',height:'90%'}}>
            {isMobile && (
              <Box
                sx={{
                  maxWidth: MpTheme.layouts.widths.sm,
                  display: 'flex',
                  alignItems: 'left',
                  justifyContent: 'left',
                }}>
                <img src={logo} alt="Logo" style={{ width: 200, height: 42 }} />
              </Box>
            )}
            <Box
              sx={{
                maxWidth: MpTheme.layouts.widths.sm,
                mx: 'auto',
                marginTop: 10,
                display: 'flex',
                alignItems: 'left',
                justifyContent: 'left',
                flexDirection: 'column',
              }}>
              {' '}
              <Tabs
                value={2}
                indicatorColor="secondary"
                textColor="primary"
                centered={false}
                sx={{ mb: 10,  ".MuiTab-root":{ alignItems: "start", }, }}>
                <Tab label="1. Sign Up" sx={{ margin: '0 5px', flexGrow: 1 }} />
                <Tab
                  label="2. Verify"
                  sx={{ textTransform: 'none', margin: '0 5px', flexGrow: 1 }}
                />
                <Tab
                  label="3. Setup Profile"
                  sx={{
                    margin: '0 5px',
                    flexGrow: 1,
                    color: 'inherit',
                    '&.Mui-selected': { color: 'secondary.main' },
                  }}
                />
              </Tabs>
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'left',
                  maxWidth: MpTheme.layouts.widths.md,
                  width: '100%',
                  justifyContent: 'left',
                  mb: 10,
                }}
                spacing={5}>
                <Typography variant={'h2'} sx={{ mt: 10 }}>
                  Company Information
                </Typography>
                <CorporateProfileForm
                  submitLabel={'Get Started'}
                  onSubmit={() =>
                    navigate('/success/signup', { replace: true })
                  }
                  allowEmployeeEdits
                />
              </Stack>
            </Box>
            <Box
              sx={{
                maxWidth: MpTheme.layouts.widths.sm,
                display: 'flex',
                width:'100%',
                alignItems: 'center',
                position:'absolute',
                bottom:!isMobile?'-50px':'-80px',
                justifyContent: 'center',
                flexDirection: 'column',
              }}>
              <Stack direction="row" spacing={10} justifyContent="space-between">
                <Link
                  variant="body2"
                  href="https://meprism.com/terms-conditions"
                  sx={{ color: '#9135E0' }}>
                  Terms & Conditions
                </Link>
                <Link
                  variant="body2"
                  href="https://meprism.com/privacy-policy"
                  sx={{ color: '#9135E0' }}>
                  Privacy Policy
                </Link>
                <Link
                  variant="body2"
                  href="https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17"
                  sx={{ color: '#9135E0' }}>
                  Contact Us
                </Link>
              </Stack>
            </Box></Box>
          </Grid>
          {isMobile && <SignInSideLeft />}
        </Grid>
      </ThemeProvider>
    </>
  )
}
