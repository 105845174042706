import React, { useMemo, useState } from 'react'
import {
  AddressAutocomplete,
  CorporateAddress,
} from '../../inputs/AddressAutocomplete'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  asCorporateProfilePost,
  corporateProfileWithoutAddress,
  CorporateProfileWithoutAddressType,
  fromCorporateProfileSlice,
} from '../../../schemas/CorporateProfile'
import { zodResolver } from '@hookform/resolvers/zod'
import MpTheme from '../../../config/MpTheme'
import {
  Box,
  Button,
  Checkbox,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { PhoneNumberMaskedInput } from '../../shared/atom/inputs/PhoneNumberInput'
import { Toast } from '../../toast/toast'
import {
  CorporateProfileSelector,
  postCorporateProfile,
} from '../../../redux/CorporateProfile/CorporateProfileSlice'
import { useAppDispatch } from '../../../redux/storeExports'
import { fonts } from '../../../config/SigninTheme'

export type CorporateFormProps = {
  submitLabel: string
  onSubmit?: () => void
  allowEmployeeEdits?: boolean
}

export const CorporateProfileForm = ({
  submitLabel,
  onSubmit,
  allowEmployeeEdits,
}: CorporateFormProps) => {
  const corporateProfile = CorporateProfileSelector()
  const dispatch = useAppDispatch()

  const [address, setAddress] = useState<CorporateAddress | undefined>(
    corporateProfile?.address,
  )
  const [addressErrorText, setAddressErrorText] = useState('')
  const [checked, setChecked] = useState(false)

  const initialFormValues = useMemo(
    () => fromCorporateProfileSlice(corporateProfile),
    [corporateProfile],
  )

  // const price = PriceSelector()

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<CorporateProfileWithoutAddressType>({
    resolver: zodResolver(corporateProfileWithoutAddress),
    defaultValues: initialFormValues,
  })

  const onSubmitHandler: SubmitHandler<
    CorporateProfileWithoutAddressType
  > = async (values) => {
    if (!address) {
      Toast.show({
        type: 'error',
        text1: 'A valid corporate address is required',
      })
      setAddressErrorText('A valid corporate address is required')
      return
    }
    const withoutAddress = asCorporateProfilePost(values)
    await dispatch(
      postCorporateProfile({
        ...withoutAddress,
        address,
      }),
    )
    if (onSubmit) {
      onSubmit()
    }
  }

  const onSubmitPress = handleSubmit(onSubmitHandler)
 
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: MpTheme.layouts.widths.md,
        width: '100%',
        mx: 'auto',
      }}>
      <Stack
        spacing={5}
        sx={{
          maxWidth: MpTheme.layouts.widths.md,
          width: '100%',
          mx: 'auto',
        }}>
        <TextField
          placeholder='Number of Employees'
          disabled={!allowEmployeeEdits}
          required
          fullWidth
          InputProps={{ inputMode: 'numeric' }}
          error={!!errors?.numberOfEmployees}
          sx={{
            height: '38px',
            '& .MuiInput-root': {
              height: '42px', // Adjust the input height
            },
            '& .MuiInputLabel-root': {
              marginBottom: '5px', // Adjust the bottom margin of the label
            },
          }}
          {...register('numberOfEmployees')}
        />

        <TextField
          placeholder='Admin Name'
          fullWidth
          required
          error={!!errors?.name}
          helperText={errors?.name?.message || ' '}
          {...register('name')}
          sx={{
            height: '38px',
            '& .MuiInput-root': {
              height: '40px', // Adjust the input height
            },
          }}
        />
        <AddressAutocomplete
          onChange={setAddress}
          defaultValue={corporateProfile?.address?.address_unified}
          errorText={addressErrorText}
        />
        <TextField
          placeholder='Email Address'
          required
          fullWidth
          error={!!errors?.email}
          helperText={errors?.email?.message || ' '}
          {...register('email')}
          sx={{
            height: '38px',
            '& .MuiInput-root': {
              height: '40px', // Adjust the input height
            },
          }}
        />
        <Controller
          control={control}
          name={'phone'}
          render={({ field }) => (
            <TextField
              required
              fullWidth
              error={!!errors?.phone}
              helperText={errors?.phone?.message || ' '}
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputComponent: PhoneNumberMaskedInput }}
              {...field}
              sx={{
                height: '38px',
                '& .MuiInput-root': {
                  height: '40px', // Adjust the input height
                },
              }}
            />
          )}
        />
        <TextField
          placeholder='Website'
          error={!!errors?.website}
          helperText={errors?.website?.message || ' '}
          fullWidth
          {...register('website')}
          sx={{
            height: '38px',
            '& .MuiInput-root': {
              height: '40px', // Adjust the input height
            },
          }}
        />
      </Stack>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center',marginTop:10 }}>
        <Checkbox checked={checked} onChange={() => setChecked(!checked)} style={{color: '#919191'}}/>
        <Typography variant={'caption'}>
          I have read and agree to the{' '}
          <Link
            // underline={'hover'}
            display={'inline'} underline={'hover'} style={{textDecoration:'underline'}}
            href={'https://www.meprism.com/mpbusiness-dpa'}>
            Business Services Data Processing Agreement
          </Link>{' '}
          ,
          <Link display={'inline'} underline={'hover'}href={'https://meprism.com/privacy-policy'} style={{textDecoration:'underline',marginRight:5}}>
                        Privacy Policy
                      </Link>
          and{' '}
          <Link
            display={'inline'} underline={'hover'}style={{textDecoration:'underline'}}
            href={'https://www.meprism.com/mpbusiness-terms'}>
            Business Terms of Service
          </Link>
          .
        </Typography>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Button
          variant={'contained'}
          type={'submit'}
          color={'darkPurple'}
          onClick={onSubmitPress}
          sx={{
            background: '#426BF0',
            width: '100%',
            '&.Mui-disabled': {
              background: '#426BF0',
              fontFamily:fonts.Inter.SemiBold,
              color: '#ffffff',
            },
          }}
          disabled={!checked}>
          {submitLabel}
        </Button>
      </Box>
      
    </Stack>
  )
}
