import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { Logger } from '@meprism/app-utils'
import { Toast } from '../components/toast/toast'
import { RootState } from './store'
import { useSelector } from 'react-redux'

export type CorporateCodePostData = {
  active: boolean
  allocated: number
  code: string
  create_time: string
  creator_arn: string
  description: string
  duration: number
  duration_type: string
  end_date: string | null
  entitlements: string[]
  expiration_date: string
  limit: number
  owner_buid: string
  title: string
  update_time: string
  _id: string
  cost_per_employee_cents: number
  authentication_type: 'default' | 'federation'
  federated_type: 'okta' | null
}
export type CorporateCodePost = {
  active: boolean
  code: string
  description: string
  duration: number
  duration_type: string
  end_date: string | null
  entitlements: string[]
  expiration_date: string
  limit: number
  owner_buid: string
  title: string
  cost_per_employee_cents: number
  type: 'default' | 'federation'
  federation_type: string | null
  authentication?: {
    type: 'default' | 'federation'
    federation_opts: { type: string; application_name: string | null }
  }
}
export type CorporateCodePut = {
  corp_code: string
  formData: CorporateCodePost
}

export interface CorporateCodeState {
  codes: CorporateCodePostData[]
}

export const initialCorporateCodeState: CorporateCodeState = {
  codes: [] as CorporateCodePostData[],
}
const createCorporateCodeApi = async (
  data: CorporateCodePost,
): Promise<CorporateCodePost> => {
  return API.post('CorpOnboarding', '/code', { body: data })
}

const updateCorporateCodeApi = (
  corp_code: string,
  data: CorporateCodePost,
): Promise<CorporateCodePost> => {
  return API.put('CorpOnboarding', `/code/${corp_code}`, { body: data })
}

const getCorporateCodeApi = async (): Promise<any> => {
  return API.get('CorpOnboarding', '/codes', {})
}

export const createCorporateCode = createAsyncThunk(
  'createCorporateCode',
  async (data: CorporateCodePost) => {
    try {
      const response = await createCorporateCodeApi(data)
      Toast.show({ type: 'success', text1: 'Corporate code has been created' })
      return response
    } catch (error) {
      Logger.error(`Error creating corporate code: ${error}`)
      const errorMessage =
        typeof error.response.data.detail === 'string'
          ? error.response.data.detail
          : 'Corporate code cannot be created'
      Toast.show({
        type: 'error',
        text1: errorMessage,
      })
      throw error
    }
  },
)

export const updateCorporateCode = createAsyncThunk(
  'updateCorporateCode',
  async (allData: CorporateCodePut) => {
    try {
      const { corp_code, formData } = allData
      const response = await updateCorporateCodeApi(corp_code, formData)
      Toast.show({ type: 'success', text1: 'Corporate code has been updated' })
      return response
    } catch (error) {
      Logger.error(`Error updating corporate code: ${error}`)
      const errorMessage =
        typeof error.response.data.detail === 'string'
          ? error.response.data.detail
          : 'Corporate code cannot be created'
      Toast.show({
        type: 'error',
        text1: errorMessage,
      })
      throw error
    }
  },
)

export const fetchAllCodes = createAsyncThunk('fetchAllCodes', async () => {
  try {
    const response = await getCorporateCodeApi()
    return response
  } catch (error) {
    Logger.error(`Error fetching users: ${error}`)
    Toast.show({
      type: 'error',
      text1: 'Error fetching corporate codes',
    })
    throw error
  }
})

const { actions, reducer } = createSlice({
  name: 'corporateCodeService',
  initialState: initialCorporateCodeState,
  reducers: {
    populateCodes: (
      state,
      { payload }: { payload: CorporateCodePostData[] },
    ) => {
      state.codes = payload
    },
  },
  extraReducers: (builder) => {
    // Add extra reducers if needed
    builder.addCase(fetchAllCodes.fulfilled, (state, { payload }) => {
      state.codes = payload.result
    })
  },
})

export const CorporateCodeServiceActions = actions
export const CorporateCodeServiceReducer = reducer

export const CorporateCodeServiceSelector = () => (state: RootState) =>
  state.corporateCodeService

export const CorporateCodesSelector = () => {
  return useSelector((state: RootState) => state.corporateCodeService.codes)
}