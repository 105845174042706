const getColumnsForManualExecution = (order: string, onSortChange: any) => [
  {
    id: 'Action',
    alignment: 'left',
  },
  {
    id: 'muid',
    label: 'Muid',
    sortBy: 'muid',
    alignment: 'left',
  },
  {
    id: 'data_broker',
    label: 'Data Broker',
    sortBy: 'data_broker',
    alignment: 'left',
  },
  {
    id: 'Execution Status',
    label: 'Execution Status',
    sortBy: 'status',
    alignment: 'left',
  },
  {
    id: 'Status',
    label: 'Status',
    sortBy: 'detail.status',
    alignment: 'left',
  },
  {
    id: 'ProfileUrl',
    label: 'ProfileUrl',
    sortBy: 'link',
    alignment: 'left',
  },
  {
    id: 'Removal Execution Time',
    label: 'Removal Execution Time',
    alignment: 'right',
    sortDirection: order,
    sortBy: 'update_time',
    onClick: onSortChange,
  },
]

export default getColumnsForManualExecution
