import { memo } from 'react'
import { Button, Container, Divider, Grid, TextField } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useMemo } from 'react'

import {
  IBrokerRecordsActions,
  IBrokerRecordsParams,
} from '../atom/inputs/brokerRecordTypes'
import AutoCompleteSelect from '../atom/AutoCompleteSelect'
import {
  BROKER_EXECUTION_STATUSES,
  BROKER_STATUSES,
} from '@meprism/shared/src/utils/constants'
import CustomDatePicker from '../atom/inputs/DatePicker'
import { exportBrokerRecords } from '../../../redux/brokerService'
import { useAppDispatch } from '../../../redux/storeExports'

interface BrokerOption {
  title: string
  id: string
}

interface FilterContainerProps {
  brokerRecordsReqParams: IBrokerRecordsParams
  dataBrokerOptions: BrokerOption[]
  handleRefetch: () => void
  dispatchBrokerRecordsReqParams: React.Dispatch<IBrokerRecordsActions>
  disableBroker: boolean
}

const FilterContainer = ({
  dataBrokerOptions,
  handleRefetch,
  brokerRecordsReqParams,
  dispatchBrokerRecordsReqParams,
  disableBroker,
}: FilterContainerProps) => {
  const { filters } = brokerRecordsReqParams
  const appDispatch = useAppDispatch()
  const startDate = useMemo(() => dayjs(filters.startDate), [filters.startDate])
  const endDate = useMemo(() => dayjs(filters.endDate), [filters.endDate])
  const minEndDate = useMemo(
    () => dayjs(filters.startDate) || dayjs(),
    [filters.startDate],
  )

  const handleFilterChange = useCallback(
    (filterKey: string, value: string | any[] | Dayjs | null) => {
      dispatchBrokerRecordsReqParams({
        type: 'UPDATE_FILTERS',
        payload: {
          [filterKey]: value,
        },
      })
    },
    [dispatchBrokerRecordsReqParams],
  )

  const handleExport = useCallback(
    () => appDispatch(exportBrokerRecords(brokerRecordsReqParams)),
    [appDispatch, brokerRecordsReqParams],
  )

  return (
    <Container>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item lg={4} md={6} xs={12}>
          <TextField
            value={filters.muid}
            onChange={(e) => handleFilterChange('muid', e.target.value)}
            label="Search MUID"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <AutoCompleteSelect
            options={BROKER_EXECUTION_STATUSES}
            onChange={(values) =>
              handleFilterChange('executionStatuses', values)
            }
            value={filters.executionStatuses}
            label="Execution Status"
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <AutoCompleteSelect
            options={BROKER_STATUSES}
            onChange={(values) => handleFilterChange('statuses', values)}
            label="Status"
            value={filters.statuses}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <AutoCompleteSelect
            options={dataBrokerOptions}
            onChange={(values) => handleFilterChange('brokerNames', values)}
            label="Data Broker"
            value={filters.brokerNames}
            disabled={disableBroker}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <CustomDatePicker
            label="Start Date"
            value={startDate}
            onChange={(values) => handleFilterChange('startDate', values)}
            disableFuture={true}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <CustomDatePicker
            label="End Date"
            value={endDate}
            minDate={minEndDate}
            onChange={(values) => handleFilterChange('endDate', values)}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 5, mb: 5 }} />
      <Grid container justifyContent="end" alignItems="center">
        <Grid
          item
          lg={2}
          md={6}
          xs={12}
          container
          justifyContent="end"
          alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleRefetch()}
            sx={{ minWidth: '150px', height: '42px' }}>
            Search
          </Button>
        </Grid>
        <Grid
          item
          lg={2}
          md={6}
          xs={12}
          container
          justifyContent="end"
          alignItems="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleExport}
            sx={{ minWidth: '150px' }}>
            Export
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 5, mb: 5 }} />
    </Container>
  )
}

export default memo(FilterContainer)
