import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

type IAutoCompleteSelect = {
  label: string
  onChange: (newValues: string[]) => void
  options: IOption[]
  placeholder?: string
  fontSize?: '5px'
  value: string[]
  disabled?: boolean
}

type IOption = {
  title: string
  id: string
}

const AutoCompleteSelect = ({
  options,
  onChange,
  label,
  fontSize,
  value,
  disabled,
}: IAutoCompleteSelect) => {
  const handleChange = (_: any, value: IOption[]) => {
    onChange(value.map(({ id }) => id))
  }

  return (
    <Autocomplete
      sx={{ width: '100%', fontSize: `${fontSize}` }}
      multiple
      limitTags={1}
      onChange={handleChange}
      id="multiple-limit-tags"
      size={'small'}
      options={options}
      value={options.filter((option) => value.includes(option.id))}
      getOptionLabel={(option) => option?.title}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          sx={{ fontSize: `${fontSize} !important` }}
        />
      )}
      disabled={disabled}
      blurOnSelect={true}
    />
  )
}

export default AutoCompleteSelect
