import {  Skeleton, Table, TableHead, TableRow, TableCell, TableContainer, Paper, TableBody} from '@mui/material'
import React, { Suspense, useEffect } from 'react'
import { CorporateCodesSelector,fetchAllCodes } from '../../../redux/corporateCodeService'
import { useAppDispatch } from '../../../redux/storeExports'
import { Await, useRouteLoaderData } from 'react-router-dom'
import CodeListItem from '../atom/CodeListItem'

interface EmailListProps {
  removed?: boolean
}

const EmailList = (props: EmailListProps) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchAllCodes());
  }, [dispatch]);
  const Codes = CorporateCodesSelector()
 
    return (
     
         <TableContainer component={Paper} sx={{ minWidth: 850 }} aria-label="simple table">
        <Table stickyHeader size="small" >
          <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell>Titile</TableCell>
            <TableCell align="right">Code</TableCell>
            <TableCell align="right">Owner BUID</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
        {Codes.map((employeeObj, index) => (
          <CodeListItem code={employeeObj} key={index} />
        ))}
        </TableBody>
        </Table>
        </TableContainer>
    
    )
}

const EmailItemSkeleton = () => {
  return (
      <Skeleton />
  )
   
}

const EmailListSkeleton = () => {
  return (
    <>
      {[0, 0, 0, 0, 0].map((_, index) => (
        <EmailItemSkeleton key={index} />
      ))}
    </>
  )
}

const EmailListSuspense = (props: EmailListProps) => {
  const loaderData = useRouteLoaderData('main') as any
  return (
      <Suspense fallback={<EmailListSkeleton />}>
        <Await
          resolve={loaderData.users}
          errorElement={<EmailList {...props} />}>
          <EmailList {...props} />
        </Await>
      </Suspense>
  )
}

export default EmailListSuspense
