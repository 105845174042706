import AppBar from '@mui/material/AppBar'
import React from 'react'
import Stack from '@mui/material/Stack'
import { NavLink, useMatches } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { SettingsSubheader } from './SettingsSubheader'
import { Button } from '@mui/material'

interface SettingsNavigationItemProps {
  destination: string
  label: string
}

interface HomeHeaderProps {
  allBrokers?: Boolean
}

const getPathComponent = (path: string) => {
  switch (path) {
    case 'whitepages':
    case 'manage':
    case 'update':
      return path
    default:
      return 'whitepages'
  }
}

const SettingsNavigationItem = ({
  destination,
  label,
}: SettingsNavigationItemProps) => {
  return (
    <NavLink to={destination}>
      {({ isActive }) => (
        <Button variant={isActive ? 'contained' : 'text'}>{label}</Button>
      )}
    </NavLink>
  )
}

export const HomeHeader = ({ allBrokers }: HomeHeaderProps) => {
  // const profileName = useAppSelector(ProfileSelectors.selectProfileName)
  // @TODO: Replace this
  const matches = useMatches()
  const routeComponents = matches[matches.length - 1].pathname
    .replace(/\/$/, '')
    .split('/')

  const pathName =
    routeComponents[1] === 'manual-removals'
      ? 'manual-removals'
      : getPathComponent(routeComponents[2])
  return (
    <AppBar
      position={'relative'}
      color={'transparent'}
      elevation={0}
      sx={{
        maxWidth: 'xl',
        justifySelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        mx: 'auto',
        px: 5,
        mt: '28px',
      }}>
      <Grid container rowSpacing={10}>
        {pathName !== 'manual-removals' && (
          <Grid
            container
            item
            xs={12}
            sm={2}
            sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Stack direction={'row'} spacing={2} sx={{ flexGrow: 0 }}>
              <SettingsNavigationItem
                destination={'results'}
                label={'Whitepages'}
              />
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <SettingsSubheader pathName={pathName} />
        </Grid>
      </Grid>
    </AppBar>
  )
}
