import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import {
  getPreferredMfa,
  logOutUser,
  selectAccountEmail,
  selectMuid
} from '@meprism/shared/src/redux/authentication/authenticationSlice'

import { LoaderFunction,  useNavigate } from 'react-router-dom'
import { AuthLoader } from '../../gateways/AuthLoader'
import { store } from '../../../redux/store'

import MpTheme from '../../../config/MpTheme'

const innerBoxSx = {
  maxWidth: MpTheme.layouts.widths.sm,
  mx: 'auto',
  width: '100%',
} as const

export const accountLoader: LoaderFunction = async () => {
  // note that NOT unwrapping this is intentional, if we can't get their MFA, then we'll render the
  // screen as though it's not set
  return store.dispatch(getPreferredMfa()).unwrap()
}



// const needingVerification = [...Object.entries(data)].filter(
//   ([_key, value]) => !(value as any)?.isUpdated,
// )
// if (needingVerification.length > 0) {
//   Logger.info(
//     `Attributes need verification: ${JSON.stringify(
//       needingVerification,
//     )}`,
//   )
// } else {
//   Logger.info('All updates were processed successfully')
// }

const Account = () => {
  const accountEmail = useAppSelector(selectAccountEmail)
 
  const muid = useAppSelector(selectMuid)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLogout = async () => {
    await dispatch(logOutUser()).unwrap()
    navigate('/login')
  }

 

  return (
    <AuthLoader>
      {/* <DeleteAccountModal
          open={deleteAccountOpen}
          setOpen={setDeleteAccountOpen}
        /> */}
      <Stack
        divider={<Divider sx={{ mt: 4, mb: 10 }} />}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: MpTheme.layouts.widths.md,
          width: '100%',
          mx: 'auto',
          mb: 10,
        }}>
        <Box sx={innerBoxSx}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Typography variant={'h2'}>mePrism ID</Typography>
            <Button
              variant="text"
              onClick={() => {
                navigator.clipboard.writeText(muid as string)
              }}>
              Copy
            </Button>
          </Box>
          <Typography variant={'body2'}>{muid}</Typography>
        </Box>

        <Box sx={innerBoxSx}>
          <Typography variant={'h2'}>Sign In Information</Typography>
          <TextField
            value={accountEmail}
            fullWidth
            disabled
            sx={{ marginTop: '20px', marginBottom: '32px' }}
          />
          {/* <Button>Forgot Password?</Button> */}

          {/* <Link to={'/resetPassword'} state={{ from: location.href }}>
              <Button variant={'text'}>Forgot Password?</Button>
            </Link> */}
        </Box>
        <Box sx={innerBoxSx}>
          <Box sx={{ mr: 'auto' }}>
            <Button
              variant={'outlined'}
              color={'darkPurple'}
              onClick={handleLogout}>
              Sign Out
            </Button>
          </Box>
        </Box>
      </Stack>
    </AuthLoader>
  )
}

export default Account
