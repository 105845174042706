import * as React from 'react'
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
} from '@mui/material'
import TableRow from '@mui/material/TableRow'
import Row from '../atom/Row'
import { visuallyHidden } from '@mui/utils'
import CustomTablePagination from '../atom/TablePagination/TablePagination'

type ColumnTypes = {
  id: string
  label?: string
  alignment?: string
  sortDirection?: string
  onClick?: (order: 'string') => void
}

type DataTableTypes = {
  columns: ColumnTypes[]
  rows: any[]
  rowType: 'manual-removal' | 'white-pages'
  onPageChange?: any
  onPageSizeChange?: any
  hasNext?: boolean
  pageSize: number
  page: number
  handleSort: (property: string) => void
}

const DataTable = ({
  columns,
  rows,
  rowType,
  onPageChange,
  onPageSizeChange,
  hasNext,
  pageSize,
  page,
  handleSort,
}: DataTableTypes) => {
  return (
    <TableContainer component={Paper} aria-label="manual-removal-table">
      <Table
        stickyHeader
        size="small"
        sx={{ maxHeight: 600, overflow: 'auto' }}>
        <TableHead>
          <TableRow>
            {columns.map((column: any) => (
              <TableCell
                key={column.id}
                align={column.alignment}
                sortDirection={column.sortDirection}>
                {column.label}
                {column.sortDirection && (
                  <TableSortLabel
                    direction={column.sortDirection === 'asc' ? 'asc' : 'desc'}
                    onClick={() => handleSort(column.sortBy)}
                    active={true}>
                    <Box component="span" sx={visuallyHidden}>
                      {column.sortDirection === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {rows.map((brokerResults: any, index: any) => (
          <Row
            brokerResults={brokerResults}
            key={index}
            rowFormat={rowType}
            disableRemoveButton={brokerResults.data_broker !== 'beenverified'}
            manualRemovals={true}
          />
        ))}
      </Table>
      <CustomTablePagination
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        hasNext={hasNext}
        pageSize={pageSize}
        page={page}
      />
    </TableContainer>
  )
}

export default DataTable
