import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  CorporateProfile,
  CorporateProfileApiPost,
  CorporateProfilePost,
  CorporateProfileReturn,
  CorporateProfileSlice,
} from './CorporateProfileTypes'
import { useAppSelector } from '../storeExports'
import { getBuidFromToken } from '../../services/AuthenticationService'
import { API } from 'aws-amplify'
import { Logger } from '@meprism/app-utils'
import { Toast } from '../../components/toast/toast'

const getCorporateProfileApi = (): Promise<CorporateProfileReturn> => {
  console.log("getting your profile")
  return API.get('Corporate', '/profile', {})
}

const makeCorporateProfileApiPost = (profile: CorporateProfileApiPost) => {
  return API.put('Corporate', '/profile', { body: profile })
}

export const corporateProfileInitialState: CorporateProfileSlice = {
  profile: undefined,
}

const buildProfileFromBackendReturn = ({
  company_name,
  url,
  phone,
  email,
  address,
  employee_num,
}: CorporateProfileReturn): CorporateProfile => ({
  companyName: company_name,
  website: url,
  phone,
  email,
  address,
  numberOfEmployees: employee_num,
})

export const getCorporateProfile = createAsyncThunk(
  'getCorporateProfile',
  async () => {
    try {
      const profileReturn = await getCorporateProfileApi()
      return buildProfileFromBackendReturn(profileReturn)
    } catch (error) {
      if (error?.response?.status === 404) {
        Logger.info('No profile found')
      } else {
        Logger.error(`Error fetching profile: ${error}`)
      }
      throw error
    }
  },
)

export const postCorporateProfile = createAsyncThunk(
  'postCorporateProfile',
  async (profile: CorporateProfilePost) => {
    try {
      const buid = await getBuidFromToken()
      if (!buid) {
        throw Error('No buid')
      }
      const ret = await makeCorporateProfileApiPost({
        ...profile,
        buid,
        username: buid,
      })
      Toast.show({ type: 'success', text1: 'Your profile has been saved' })
      return ret
    } catch (error) {
      Logger.error(`Error posting corporate profile: ${error}`)
      Toast.show({
        type: 'error',
        text1: 'Your profile could not be saved',
      })
      throw error
    }
  },
)

const { reducer } = createSlice({
  name: 'corporateProfile',
  initialState: corporateProfileInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCorporateProfile.fulfilled, (state, action) => {
      state.profile = action.payload
    })
    builder.addCase(postCorporateProfile.fulfilled, (state, action) => {
      const { company_name, url, phone, email, address, employee_num } =
        action.meta.arg
      state.profile = {
        companyName: company_name,
        website: url,
        phone,
        email,
        address,
        numberOfEmployees: employee_num,
      }
    })
  },
})

export const CorporateProfileReducer = reducer

export const CorporateProfileSelector = () =>
  useAppSelector((state) => state.corporateProfile.profile)
