import AppBar from '@mui/material/AppBar'
import React from 'react'
import Stack from '@mui/material/Stack'
import { NavLink, useMatches } from 'react-router-dom'
import { fonts } from '../../../theme/theme'
import Grid from '@mui/material/Grid'
import { SettingsSubheader } from './SettingsSubheader'
import { Button, Typography } from '@mui/material'

interface SettingsNavigationItemProps {
  destination: string
  label: string
}

const getPathComponent = (path: string) => {
  switch (path) {
    case 'account':
      return path
    default:
      return 'account'
  }
}

const SettingsNavigationItem = ({
  destination,
  label,
}: SettingsNavigationItemProps) => {
  return (
    <NavLink to={destination}>
      {({ isActive }) => (
        <Button variant={isActive ? 'contained' : 'text'}>{label}</Button>
      )}
    </NavLink>
  )
}

export const SettingsHeader = () => {
  // const profileName = useAppSelector(ProfileSelectors.selectProfileName)
  // @TODO: Replace this
  const profileName = 'mePrism Inc'
  const matches = useMatches()
  const routeComponents = matches[matches.length - 1].pathname
    .replace(/\/$/, '')
    .split('/')
  const pathName = getPathComponent(routeComponents[routeComponents.length - 1])

  return (
    <AppBar
      position={'relative'}
      color={'transparent'}
      elevation={0}
      sx={{
        maxWidth: 'xl',
        justifySelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        mx: 'auto',
        px: 5,
        mt: '28px',
      }}>
      <Grid container rowSpacing={10}>
        <Grid item xs={12} sm>
          <Typography variant={'h1'} sx={{ fontFamily: fonts.Jakarta.Bold }}>
            {profileName}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={4} justifyContent={'center'}>
          <Stack direction={'row'} spacing={2} sx={{ flexGrow: 0 }}>
            <SettingsNavigationItem destination={'account'} label={'Account'} />
          </Stack>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs={12}>
          <SettingsSubheader pathName={pathName} />
        </Grid>
      </Grid>
    </AppBar>
  )
}
